import { useScrollTop } from "./useScrollTop";

export const useMyHead = (story: Object) => {
  const { locale } = useI18n();
  const runtimeConfig = useState("runtimeConfig");
  const config = runtimeConfig.value;
  const utils = useUtils();
  const localePath = useLocalePath();

  useSeoMeta({
    title: () =>
      story.content?.metatags?.title?.replace("zweikern | ", "") || story.name,
    description: () => story.content?.metatags?.description,
  });

  useHead({
    htmlAttrs: {
      lang: locale,
      "data-wf-page": "667e63d40e1f4b734e2e1336",
      "data-wf-site": "667e63d40e1f4b734e2e12d7",
    },
    link: [
      {
        rel: "alternate",
        href: `${config.public.siteUrl}/${story.default_full_slug !== "home" ? utils.forceTrailingSlash(story.default_full_slug) : ""}`,
        hreflang: "de",
        title: story.name,
      },
      ...(story.translated_slugs || []).map((translation) => ({
        rel: "alternate",
        href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== "home" ? utils.forceTrailingSlash(translation.path) : ""}`,
        hreflang: translation.lang,
        title: translation.name,
      })),
      ...(story.translated_slugs || []).map((translation) => ({
        rel: "alternate",
        href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== "home" ? utils.forceTrailingSlash(translation.path) : ""}`,
        hreflang: "x-default",
        title: translation.name,
      })),
      {
        rel: "canonical",
        href:
          config.public.siteUrl +
          utils.forceTrailingSlash(
            story.default_full_slug !== "home"
              ? "/" + story.full_slug
              : localePath("/"),
          ),
      },
    ],
  });

  // const { showScrollToTopButton } = useScrollTop()

  return {
    // showScrollToTopButton
  };
};
